import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import HomeNew from '../views/HomeNew.vue'
import Home2 from '../views/Home2.vue'
import Impressum from '../views/Impressum.vue'
import Datenschutz from '../views/Datenschutz.vue'
import erstes_bestes_training from '../views/einmalig/ebt.vue'
import Kurse from '../views/Kurse.vue'
import courseListWeekly from '../views/gf/courseListWeekly.vue'
import courseList from '../views/gf/courseList.vue'
import courseListView from '../views/gf/courseListView.vue'
import courseListEdit from '../views/gf/courseListEdit.vue'
import courseEdit from '../views/gf/courseEdit.vue'
import courseSettings from '../views/gf/courseSettings.vue'
import courseEditTimeline from '../views/gf/courseEditTimeline.vue'
import courseCreateTimeline from '../views/gf/courseCreateTimeline.vue'
import Kurse_extern from '../views/Kurse_extern.vue'
import einsteinSaunaContest from '../views/einmalig/einsteinSaunaContest.vue'
import einsteinSaunaContestAdminList from '../views/einmalig/einsteinSaunaContestAdminList.vue'
import Push from '../views/Push.vue'
import Workouts from '../views/Workouts.vue'
import RateWorkouts from '../views/RateWorkouts.vue'
import RateAnamnese from '../views/RateAnamnese.vue'
import Videoarchiv from '../views/Videoarchiv.vue'
import WorkoutCreate from '../views/workouts/WorkoutCreate.vue'
import WorkingoutCompleted from '../views/workouts/WorkingoutCompleted.vue'
import WorkingoutCompletedOld from '../views/workouts/WorkingoutCompletedOld.vue'
import WorkoutEdit from '../views/workouts/WorkoutEdit.vue'
import Friends from '../views/friends/Friends.vue'
import DWorkouts from '../views/workouts/Workouts.vue'
import WorkoutRun from '../views/workouts/Workingout.vue'
import PlanRun from '../views/workouts/PlanRun.vue'
import WorkoutStatistik from '../views/workouts/Statistik.vue'
// import Statistik from '../views/Statistik.vue'
import Sensordaten from '../views/Sensordaten.vue'
import Schnupperwoche from '../views/Schnupperwoche.vue'
import Zeitplan from '../views/kurse/Zeitplan.vue'
import ZeitplanCreator from '../views/kurse/ZeitplanCreator.vue'
import Zeitplaene from '../views/kurse/Zeitplaene.vue'
import Instruktoren from '../views/admin/Instruktoren.vue'
import Rollenverwaltung from '../views/admin/Rollenverwaltung.vue'
import Kursanmeldung from '../views/kurse/Anmeldung.vue'
import Aktuelles from '../views/Aktuelles.vue'
import Einstellungen from '../views/Einstellungen.vue'
import Terminplaner from '../views/Terminplaner.vue'
import Terminplaner_auswahl from '../views/Terminplaner_auswahl.vue'
import Terminplaner_pt from '../views/Terminplaner_pt.vue'
import Terminplaner_auswahl_pt from '../views/Terminplaner_auswahl_pt.vue'
import UebungenEditor from '../views/admin/Uebungen.vue'
import Survey from '../views/admin/Survey.vue'
import Playground from '../views/admin/Playground.vue'
import timetrackerAdmin from '../views/admin/timetracker/timetracker.vue'
import Bewertungen from '../views/admin/Bewertungen.vue'
import Studiovergleich from '../views/admin/Studiovergleich.vue'
import Kreuztabelle from '../views/admin/Kreuztabelle.vue'
import Kreuztabellen from '../views/admin/Kreuztabellen.vue'
import Intern from '../views/admin/Intern.vue'
import Livestream from '../views/einmalig/Livestream.vue'
import Kampagne_customer from '../views/Kampagne_customer.vue'
import Management from '../views/Management.vue'
import Kurseinstellungen from '../views/admin/Kurseinstellungen'
import Kursbewertungen from '../views/admin/Kursbewertungen'
import Weiterempfehlungen from '../views/admin/Weiterempfehlungen.vue'
import MonthlyReport from '../views/dashboard/monthlyReport/MonthlyReport.vue'
import contractCreator from '../views/dashboard/contract/contractCreator.vue'
import upgradeContractView from '../views/dashboard/contract/components/upgradeContractView.vue'
import tacContractsRoodyMapper from '../views/dashboard/contract/components/tacContractsRoodyMapper.vue'
import contractCreatorExtern from '../views/dashboard/contract/contractCreatorExtern.vue'
import EmployeeTimeTracker from '../views/dashboard/timetracker/EmployeeTimeTracker.vue'
import ManagementTimeTracker from '../views/dashboard/timetracker/ManagementTimeTracker.vue'
import OfficeTimeTracker from '../views/dashboard/timetracker/OfficeTimeTracker.vue'
import payment from '../views/dashboard/payment/Payment.vue'
import paymentSettings from '../views/dashboard/payment/PaymentSettings.vue'
import paymentOverview from '../views/dashboard/payment/PaymentOverview.vue'
import homeDashboardContractStatistics from '../views/dashboard/home/homeDashboardContractStatistics.vue'
import homeDashboardContractDevelopment from '../views/dashboard/home/homeDashboardContractDevelopment.vue'
import homeDashboardLeadStatistics from '../views/dashboard/home/homeDashboardLeadStatistics.vue'
import registrationForm from '../views/launchScreen/launchScreen.vue'
import Printer from '../views/Printer.vue'
import Forgot from '../views/Forgot.vue'
import Livestream_Blacklist from '../views/Livestream_Blacklist.vue'
import Membercard from '../views/Membercard.vue'
import Anamneseboegen from '../views/admin/Anamneseboegen.vue'
import Kursstatistik from '../views/admin/Kursstatistik.vue'
import Adventsgewinnspiel from '../views/einmalig/Adventsgewinnspiel.vue'
import Zeitgutschrift from '../views/einmalig/Zeitgutschrift.vue'
import Timestop from '../views/einmalig/Timestop.vue'
import GSPhoto from '../views/einmalig/GSPhoto.vue'
import Zoom from '../views/einmalig/Zoom.vue'
import Weiterempfehlung from '../views/Weiterempfehlung.vue'
import { EventBus } from '../event-bus'
import Tendays_Feedback from '../views/einmalig/Tendays_Feedback.vue'
import Helsana from '../views/einmalig/helsana.vue'
import Covid_check from '../views/admin/Covid_check.vue'
import Covid_archive from '../views/admin/Covid_archive.vue'
import personalTraining from '../views/personalTraining.vue'
import aliBey from '../views/aliBey/aliBey.vue'
import aliBeyEditor from '../views/aliBey/aliBeyEditor.vue'
import reportProblem from '../views/intern/reportProblem.vue'
import viewProblem from '../views/intern/viewProblem.vue'
import aliBeyBooking from '../views/aliBey/aliBeyBooking.vue'
import fitnessCenterDisplay from '../views/fitnessCenterDisplay/fitnessCenterDisplay.vue'
import EventView from '../views/event/eventView.vue'
import EventHome from '../views/event/eventHome.vue'
import EventForm from '../views/event/eventForm.vue'
import ChristmasHome from '../views/christmas/christmasHome.vue'
import DeviceList from '../views/deviceManagement/deviceList.vue'
import DeviceForm from '../views/deviceManagement/deviceForm.vue'
import DeviceExerciseForm from '../views/deviceManagement/deviceExerciseForm.vue'
import redirection from '../views/redirection/redirection.vue'
import gfRecommendations from '../views/gf/gfRecommendations.vue'
import dashboard from '../views/dashboard/dashboard.vue'
import studioEditor from '../views/dashboard/studioEditor.vue'
import studioSignatures from '../views/dashboard/studio/studioSignatures.vue'

import contractList from '../views/dashboard/contract/contractList.vue'
import contractStats from '../views/dashboard/contract/contractStats.vue'
import contractManager from '../views/dashboard/contract/contractManager.vue'
import mapContractTAC from '../views/dashboard/tac/components/contract/mapContract.vue'
import contractSettings from '../views/dashboard/contract/contractSettings.vue'
import contractRejected from '../views/dashboard/contract/contractRejected.vue'
import contractTemporary from '../views/dashboard/contract/contractTemporary.vue'
import contractFieldsManager from '../views/dashboard/contract/contractFieldsManager.vue'
import contractLayoutManager from '../views/dashboard/contract/contractLayoutManager.vue'
import workoutsFeedback from '../views/dashboard/workouts/workoutsFeedback.vue'
import workoutsDashboard from '../views/dashboard/workouts/workoutsDashboard.vue'
import workoutsDashboardSearchAndCreate from '../views/dashboard/workouts/workoutsDashboardSearchAndCreate.vue'
import studioDashboard from '../views/dashboard/studio/studioDashboard.vue'
import studioDashboardEmployee from '../views/dashboard/studio/studioDashboardEmployee.vue'
import studioDashboardDaycare from '../views/dashboard/studio/studioDashboardDaycare.vue'
import studioDashboardSlimMe from '../views/dashboard/studio/studioDashboardSlimMe.vue'
import studioDashboardOpeningHours from '../views/dashboard/studio/studioDashboardOpeningHours.vue'
import studioDashboardWebsiteContent from '../views/dashboard/studio/studioDashboardWebsiteContent.vue'
import communicationDashboard from '../views/dashboard/communication/communicationDashboard.vue'
import communicationDashboardNews from '../views/dashboard/communication/communicationDashboardNews.vue'
import communicationDashboardPopups from '../views/dashboard/communication/communicationDashboardPopups.vue'
import communicationDashboardQRCode from '../views/dashboard/communication/communicationDashboardQRCode.vue'
import communicationDashboardCampaign from '../views/dashboard/communication/communicationDashboardCampaign.vue'
import communicationDashboardPush from '../views/dashboard/communication/communicationDashboardPush.vue'
import devicesDashboard from '../views/dashboard/devices/devicesDashboard.vue'
import devicesDashboardExerciseForm from '../views/dashboard/devices/devicesDashboardExerciseForm.vue'
import devicesDashboardForm from '../views/dashboard/devices/devicesDashboardForm.vue'
import devicesDashboardList from '../views/dashboard/devices/devicesDashboardList.vue'
import userDashboard from '../views/dashboard/user/userDashboard.vue'
//import userDashboardSearch from '../views/dashboard/user/userDashboardSearch.vue'
import userDashboardCustomer from '../views/dashboard/user/userDashboardCustomer.vue'
import userDashboardSearch from '../views/dashboard/user/userDashboardSearch.vue'
import userDashboardRightList from '../views/dashboard/user/userDashboardRightList.vue'
import communicationDashboardSlideManager from '../views/dashboard/communication/communicationDashboardSlideManager.vue'
import membercardDashboard from '../views/dashboard/membercard/membercardDashboard.vue'
import membercardDashboardAdmin from '../views/dashboard/membercard/membercardDashboardAdmin.vue'
import leadsDashboard from '../views/dashboard/leads/leadsDashboard.vue'
import leadsCostManager from '../views/dashboard/leads/leadsCostManager.vue'
import leadsDashboardLeadsDeprecated from '../views/dashboard/leads/leadsDashboardLeadsDeprecated.vue'
import leadsDashboardLeadsList from '../views/dashboard/leads/leadsDashboardLeadsList.vue'
import leadsDashboardReferralList from '../views/dashboard/leads/leadsDashboardReferralList.vue'
import leadsDashboardReferralSettings from '../views/dashboard/leads/leadsDashboardReferralSettings.vue'
import leadsDashboardSettings from '../views/dashboard/leads/leadsDashboardSettings.vue'
import anamneseDashboard from '../views/dashboard/anamnese/anamneseDashboard.vue'
import anamneseDashboardSearch from '../views/dashboard/anamnese/anamneseDashboardSearch.vue'
import anamneseDashboardSettings from '../views/dashboard/anamnese/anamneseDashboardSettings.vue'
import shopDashboard from '../views/dashboard/shop/shopDashboard.vue'
import nutritionDashboard from '../views/dashboard/nutrition/nutritionDashboard.vue'
import nutritionRoute from '../views/nutrition/nutritionRoute.vue'
import nutritionMainpage from '../views/nutrition/nutritionMainpage.vue'
import nutritionRecipes from '../views/nutrition/nutritionRecipes.vue'
import nutritionShoppinList from '../views/nutrition/nutritionShoppinList.vue'
import nutritionSearch from '../views/dashboard/nutrition/nutritionSearch.vue'
import nutritionCustomers from '../views/dashboard/nutrition/nutritionCustomers.vue'
import shopProducts from '../views/dashboard/shop/shopProducts.vue'
import shopPurchases from '../views/dashboard/shop/shopPurchases.vue'
import groupFitnessDashboard from '../views/dashboard/groupFitness/groupFitnessDashboard.vue'
import groupFitnessDashboardRatings from '../views/dashboard/groupFitness/groupFitnessDashboardRatings.vue'
import groupFitnessDashboardCourses from '../views/dashboard/groupFitness/groupFitnessDashboardCourses.vue'
import groupFitnessDashboardRooms from '../views/dashboard/groupFitness/groupFitnessDashboardRooms.vue'
import groupFitnessDashboardSettings from '../views/dashboard/groupFitness/groupFitnessDashboardSettings.vue'
import groupFitnessDashboardDownloads from '../views/dashboard/groupFitness/groupFitnessDashboardDownloads.vue'
import groupFitnessDashboardLabels from '../views/dashboard/groupFitness/groupFitnessDashboardLabels.vue'
import groupFitnessDashboardEventHome from '../views/dashboard/groupFitness/groupFitnessDashboardEventHome.vue'
import groupFitnessDashboardEventForm from '../views/dashboard/groupFitness/groupFitnessDashboardEventForm.vue'
import groupFitnessDashboardVideos from '../views/dashboard/groupFitness/groupFitnessDashboardVideos.vue'
import homeDashboard from '../views/dashboard/home/homeDashboard.vue'
import homeDashboardCheckinStatistics from '../views/dashboard/home/homeDashboardCheckinStatistics.vue'
import homeDashboardUserStatistics from '../views/dashboard/home/homeDashboardUserStatistics.vue'
import homeDashboardFigures from '../views/dashboard/home/homeDashboardFigures.vue'
import homeDashboardGroupFitnessStatistics from '../views/dashboard/home/homeDashboardGroupFitnessStatistics.vue'
import homeDashboardTrainerStatistics from '../views/dashboard/home/homeDashboardTrainerStatistics.vue'
import homeDashboardAnamneseStatistics from '../views/dashboard/home/homeDashboardAnamneseStatistics.vue'
import notificationSettings from '../views/notificationSettings.vue'
import LPReferral from '../views/referral/public.vue'
import internReferal from '../views/referral/internReferal.vue'
import mailCampaign from '../views/tools/mailCampaign.vue'
import rights from '../views/admin/rights.vue'
import landing from '../views/redesignRoody/landing.vue'
import chat from '../views/chat/chat.vue'
import trainingCalendar from '../views/TrainingCalendar.vue'
import oaff from '../views/oaff.vue'
import habitHome from '../views/habit/habitHome.vue'
import businessHours from '../views/admin/businessHours.vue'
import Leads from '../views/admin/leads/Leads.vue'
import PublicLeads from '../views/admin/leads/public/LeadView.vue'
import ReferralView from '../views/admin/leads/public/ReferralView.vue'
import internReferralView from '../views/admin/leads/internReferralView.vue'
import slimMeView from '../views/slimMe/dashboard/slimMeView.vue'
import testPage from '../views/dashboard/attendance/testPage.vue'
import liveCheckins from '../views/dashboard/attendance/liveCheckins.vue'
import consentthing from '../components/consentDeclaration/consentDeclarationCard.vue'
import shopMainpage from '../views/shop/shopMainpage.vue'
import shopRoute from '../views/shop/shopRoute.vue'
import shopCheckout from '../views/shop/shopCheckout.vue'
import shopCheckPayment from '../views/shop/shopCheckPayment.vue'
import shopPurchaseInfos from '../views/shop/shopPurchaseInfos.vue'
import shopCheckPaymentFailed from '../views/shop/shopCheckPaymentFailed.vue'

import checkPayment from '../views/payment/checkPayment.vue'
import checkPaymentFailed from '../views/payment/checkPaymentFailed.vue'
import checkPaymentSuccess from '../views/payment/checkPaymentSuccess.vue'

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		name: 'Home',
		meta: { navbarTitle: 'roody' },
		component: HomeNew,
		children: [
			{
				path: 'panel',
				name: 'employeePanel',
				meta: { hideBottomNavigation: true },
				component: () => import('../views/employee/employeePanel.vue'),
			},
		]
	},
	{ path: '/consent', name: 'consent', component: consentthing },
	{
		path: '/adyen/payment-check/:transactionID',
		name: 'adyenPaymentCheck',
		component: checkPayment,
	},
	{
		path: '/adyen/payment-success',
		name: 'adyenPaymentSuccess',
		component: checkPaymentSuccess,
	},
	{
		path: '/adyen/payment-failed',
		name: 'adyenPaymentError',
		component: checkPaymentFailed,
	},
	{
		path: '/test',
		name: 'test',
		component: testPage,
	},
	{
		path: '/slimme/dashboard',
		component: slimMeView,
	},
	{ path: '/tes/reg/2', name: 'kfjasdklfjakldsfjklsf', component: registrationForm },
	{
		path: '/oaff',
		name: 'oaff',
		component: oaff,
	},
	{
		path: '/trainingcalendar',
		name: 'TrainingCalendar',
		component: trainingCalendar,
	},
	{
		path: '/landing',
		name: 'Landing',
		component: landing,
	},
	{
		path: '/chat',
		name: 'Chat',
		component: chat,
	},
	{
		name: 'contractCreatorExtern',
		path: '/contract',
		component: contractCreatorExtern,

	},
	{
		name: 'contractCreatorExternForm',
		path: '/contract/create/:id/:studioId',
		component: contractCreator,
		meta: { displayedTitle: 'Vertrag Erstellen' }
	},
	{
		name: 'contractCreatorExternForm',
		path: '/contract/create/:id/:studioId/:skipPresale',
		component: contractCreator,
		meta: { displayedTitle: 'Vertrag Erstellen' }
	},
	{
		path: '/dashboard',
		name: 'dashboardHome',
		component: dashboard,
		children: [
			{
				name: 'healthcareView',
				path: 'healthcare',
				component: () => import('../views/dashboard/healthcareConfirmation/healthcareView.vue'),
				meta: { displayedTitle: 'KK-Bestätigung Anfragen' }
			},
			{
				name: 'timestopView',
				path: 'timestop',
				component: () => import('../views/dashboard/timestop/timestopView.vue'),
				meta: { displayedTitle: 'Timestops Anfragen' }
			},
			{
				name: 'timestopCalculator',
				path: 'timestop-calc',
				component: () => import('../views/dashboard/timestop/timestopCalculator.vue'),
				meta: { displayedTitle: 'Timestop Rechner' }
			},
			{ name: 'homeDashboardCheckinStatistics', path: 'checkin-stats', component: homeDashboardCheckinStatistics, meta: { displayedTitle: 'Check-IN Statistiken', statistics: true, multiStudio: true, rights: ['stat_viewstudio', 'stat_admin'] } },
			{ name: 'homeDashboardUserStatistics', path: 'user-stats', component: homeDashboardUserStatistics, meta: { displayedTitle: 'Kunden Statistiken', statistics: true, multiStudio: true, rights: ['stat_viewstudio', 'stat_admin'] } },
			{ name: 'homeDashboardGroupFitnessStatistics', path: 'gf-stats', component: homeDashboardGroupFitnessStatistics, meta: { displayedTitle: 'Group Fitness Statistiken', statistics: true, multiStudio: true, rights: ['stat_admin', 'stat_gf', 'stat_gf_stud'] } },
			{ name: 'homeDashboardTrainerStatistics', path: 'trainer-stats', component: homeDashboardTrainerStatistics, meta: { displayedTitle: 'Trainer Statistiken', statistics: true, multiStudio: true, rights: ['stat_admin', 'stat_viewstudio',] } },
			{ name: 'homeDashboardAnamneseStatistics', path: 'anamnese-stats', component: homeDashboardAnamneseStatistics, meta: { displayedTitle: 'Anamnese Statistiken', statistics: true, multiStudio: true, rights: ['ab_view', 'ab_edit', 'ab_delete'], } },
			{ name: 'homeDashboardContractStatistics', path: 'contract-stats', component: homeDashboardContractStatistics, meta: { displayedTitle: 'Vertrags Statistiken', statistics: true, multiStudio: true, rights: ['stat_admin', 'stat_viewstudio'] } },
			{ name: 'homeDashboardContractDevelopmentStatistics', path: 'contract-development', component: homeDashboardContractDevelopment, meta: { displayedTitle: 'Aboentwicklung (letzte 12 Monate)', statistics: true, hideRange: true, multiStudio: true, rights: ['stat_admin', 'stat_viewstudio'] } },
			{ name: 'homeDashboardLeadStatistics', path: 'lead-stats', component: homeDashboardLeadStatistics, meta: { displayedTitle: 'Leads Statistiken', statistics: true, multiStudio: true, rights: ['stat_admin', 'stat_viewstudio'] } },
			{ name: 'homeDashboardFigures', path: 'figures', component: homeDashboardFigures, meta: { monthsOnly: true, displayedTitle: 'Kennzahlen', statistics: true, multiStudio: false, rights: ['stat_admin', 'stat_viewstudio'] } },
			{ name: 'liveCheckins', path: 'live-checkins', component: liveCheckins, meta: { studioOnly: true, displayedTitle: 'Live Checkins', rights: ['g_studioastat_admin', 'stat_viewstudio', 'live_checkins'] } },
			{
				name: 'studioEditor', path: 'studios', component: studioEditor, meta: { displayedTitle: 'Studio Admin', rights: ['g_studioadmin'] }, children: [

				]
			},
			{ name: 'studioSignatures', path: 'studios/signatures', component: studioSignatures, meta: { displayedTitle: 'Studio Unterschriften', rights: ['g_studioadmin'] } },
			{ name: 'timeTracker', path: 'timetracker', component: EmployeeTimeTracker, meta: { displayedTitle: 'Zeiterfassung', rights: ['g_studioadmin', 'tt_employeeManage'] } },
			{ name: 'timeTrackerManagement', path: 'timetracker/management', component: ManagementTimeTracker, meta: { includeTeams: true, monthsOnly: true, statistics: true, displayedTitle: 'Zeiterfassung Management', rights: ['g_studioadmin', 'tt_management'] } },
			{ name: 'timeTrackerOffice', path: 'timetracker/office', component: OfficeTimeTracker, meta: { includeTeams: true, monthsOnly: true, displayedTitle: 'Zeiterfassung Büro', statistics: true, rights: ['g_studioadmin', 'tt_office'] } },
			{ name: 'contractSettings', path: 'contract/settings', component: contractSettings, meta: { displayedTitle: 'Vertrags Einstellungen' } },
			{ name: 'contractList', path: 'contract', component: contractList, meta: { displayedTitle: 'Verträge' } },
			{ name: 'contractManager', path: 'contract/manager', component: contractManager, meta: { displayedTitle: 'offene Verträge' } },
			{ name: 'contractStats', path: 'contract/stats', component: contractStats, meta: { statistics: true, multiStudio: true, displayedTitle: 'Statistik abgelehnte Verträge' } },
			{ name: 'contractManager', path: 'contract/manager/map/:userId/:index', component: mapContractTAC, meta: { displayedTitle: 'Map TAC Contract' } },
			{ name: 'contractSettings', path: 'contract/settings', component: contractSettings, meta: { displayedTitle: 'Einstellungen' } },
			{ name: 'contractRejected', path: 'contract/rejected', component: contractRejected, meta: { displayedTitle: 'abgelehnte Verträge' } },
			{ name: 'contractTemporary', path: 'contract/temporary', component: contractTemporary, meta: { displayedTitle: 'zwischengespeicherte Verträge' } },
			{ name: 'contractFieldsManager', path: 'contract/create', component: contractFieldsManager, meta: { displayedTitle: 'Vertrag erstellen' } },
			{ name: 'contractFieldsManagerEdit', path: 'contract/settings/:id', component: contractFieldsManager, meta: { displayedTitle: 'Vertrag bearbeiten' } },
			{
				name: 'contractCreator',
				path: 'contract/create/:id',
				component: contractCreator,
				meta: { displayedTitle: 'Vertrag Bearbeiten' }
			},
			{
				name: 'tacContractsRoodyMapper',
				path: 'contract/upgrade/mapping',
				component: tacContractsRoodyMapper,
				meta: { displayedTitle: 'Vertrag Infos Mappen' }
			},

			{
				name: 'contractUpgradesOpen',
				path: 'contract/upgrade/created',
				component: upgradeContractView,
				meta: { displayedTitle: 'Vertrag upgraden' }
			},
			{
				name: 'contractUpgradesClosed',
				path: 'contract/upgrade/rejected',
				component: upgradeContractView,
				meta: { displayedTitle: 'Vertrag upgraden' }
			},
			{
				name: 'contractUpgradesAccepted',
				path: 'contract/upgrade/accepted',
				component: upgradeContractView,
				meta: { displayedTitle: 'Vertrag upgraden' }
			},
			{
				name: 'upgradeContractView',
				path: 'contract/upgrade/deleted',
				component: upgradeContractView,
				meta: { displayedTitle: 'Vertrag upgraden' }
			},

			{
				name: 'monthlyReport',
				path: 'monthly/report',
				component: MonthlyReport,
				meta: { displayedTitle: 'Monats Übersicht' }
			},
			{
				name: 'contractViewer',
				path: 'contract/view/:userId/:index',
				component: contractCreator,
				meta: { displayedTitle: 'Dein Vertrag' }
			},
			{
				name: 'contractEditor',
				path: 'contract/edit/:userId/:index',
				component: contractCreator,
				meta: { displayedTitle: 'Vertrag bearbeiten' }
			},
			{ name: 'contractLayoutManager', path: 'contract/layout', component: contractLayoutManager },
			{
				name: 'payment', path: 'payment', component: payment, meta: { displayedTitle: 'Zahlungsabwicklung' },

			},
			{
				name: 'paymentOverview', path: 'payment/overview', component: paymentOverview, meta: { displayedTitle: 'Zahlungsübersicht' },

			},
			{ name: 'paymentSettings', path: 'payment/settings', component: paymentSettings, meta: { displayedTitle: 'Einstellungen' } },
			{
				name: 'workouts',
				path: 'workouts',
				component: workoutsDashboard,
				children: [
					{ name: 'workoutsDashboardFeedback', path: 'feedback', component: workoutsFeedback, meta: { displayedTitle: 'Trainingsplan Bewertungen', needsLoader: true, rights: ['tp_feedback'], } },
					{ name: 'workoutsDashboardSearchAndCreate', path: 'search-and-create', component: workoutsDashboardSearchAndCreate, meta: { displayedTitle: 'Trainingsplan suchen und erstellen', rights: ['tp_create', 'tp_viewAll'], } }],
			},
			{
				name: 'studio',
				path: 'studio',
				component: studioDashboard,
				children: [
					{ name: 'studioEmployee', path: 'employee', component: studioDashboardEmployee, meta: { studioOnly: true, multiStudio: true, includeTeams: true, displayedTitle: 'Mitarbeiter', needsLoader: true, rights: ['g_employeemanager'], } },
					{ name: 'studioDaycare', path: 'daycare', component: studioDashboardDaycare, meta: { displayedTitle: 'Kinderhort', needsLoader: true, rights: ['g_daycare'], }, },
					{ name: 'openingHours', path: 'opening-hours', component: studioDashboardOpeningHours, meta: { studioOnly: true, multiStudio: true, displayedTitle: 'Öffnungszeiten', needsLoader: true, rights: ['g_employeemanager'], }, },
					{ name: 'websiteContent', path: 'website', component: studioDashboardWebsiteContent, meta: { displayedTitle: 'Webseite', rights: ['g_websitecontent'], }, },
					{ name: 'studioSlimMe', path: 'slim-me', component: studioDashboardSlimMe, meta: { displayedTitle: 'Slim ME', needsLoader: true, rights: ['u_search'] } },
				],
			},
			{
				name: 'communication',
				path: 'communication',
				component: communicationDashboard,
				children: [
					{ name: 'communicationNews', path: 'news', component: communicationDashboardNews, meta: { displayedTitle: 'News', needsLoader: true, rights: ['mk_news'], } },
					{ name: 'communicationPopups', path: 'popups', component: communicationDashboardPopups, meta: { displayedTitle: 'Popups', needsLoader: true, rights: ['mk_popup'], } },
					{ name: 'communicationQRCode', path: 'qrcode', component: communicationDashboardQRCode, meta: { displayedTitle: 'QR-Code', rights: ['mk_qrcode'], } },
					{ name: 'communicationDashboardCampaign', path: 'campaign', component: communicationDashboardCampaign, meta: { displayedTitle: 'Kampagne', needsLoader: true, rights: ['mk_campaigns'], } },
					{ name: 'communicationDashboardPush', path: 'push', component: communicationDashboardPush, meta: { displayedTitle: 'Push', rights: ['mk_push'], } },
					{ name: 'communicationDashboardSlideManager', path: 'slides', component: communicationDashboardSlideManager, meta: { displayedTitle: 'Slides', needsLoader: true, rights: ['mk_slider'], } },
				],
			},
			/*
			path: '/devices',
		name: 'DeviceList',
		component: DeviceList,
		children: [
			{
				path: 'create',
				name: 'DeviceCreate',
				component: DeviceForm,
				children: [
					{
						path: 'exercise/create',
						name: 'DeviceExerciseCreate',
						component: DeviceExerciseForm,
					},
				],
			},
			{
				path: ':id',
				name: 'DeviceEdit',
				component: DeviceForm,
				children: [
					{
						path: 'exercise/create',
						name: 'DeviceExerciseCreate',
						component: DeviceExerciseForm,
					},
					{
						path: 'exercise/:eid',
						name: 'DeviceExerciseEdit',
						component: DeviceExerciseForm,
					},
				],
			},
			*/
			{
				name: 'devices',
				path: 'devices',
				component: devicesDashboard,
				children: [
					{ name: 'devicesDashboardList', path: 'list', component: devicesDashboardList, meta: { displayedTitle: 'Geräte Verwaltung', needsLoader: true, rights: ['ex_create', 'ex_edit', 'ex_delete', 'ex_transfer'], } },
					{ name: 'devicesDashboardForm', path: 'form', component: devicesDashboardForm, meta: { rights: ['ex_create', 'ex_edit', 'ex_delete', 'ex_transfer'], } },
					{ name: 'devicesDashboardExerciseForm', path: 'exerciseform', component: devicesDashboardExerciseForm, meta: { rights: ['ex_create', 'ex_edit', 'ex_delete', 'ex_transfer'], } },
				],
			},
			{
				name: 'shop',
				path: 'shop',
				component: shopDashboard,
				children: [
					{ name: 'shopProducts', path: 'products', component: shopProducts, meta: { displayedTitle: 'Produkte', rights: ['shop_manage'], } },
					{ name: 'shopPurchases', path: 'purchases', component: shopPurchases, meta: { displayedTitle: 'Einkäufe', rights: ['shop_purchase_view', 'shop_manage'], } },
				],
			},
			{
				name: 'nutrition',
				path: 'nutrition',
				component: nutritionDashboard,
				children: [
					{ name: 'nutritionSearch', path: 'search', component: nutritionSearch, meta: { displayedTitle: 'Ernährung Suche', rights: ['stat_admin', 'nutrition_admin'], } },
					{ name: 'nutritionCustomers', path: 'customers', component: nutritionCustomers, meta: { displayedTitle: 'Kunden', rights: ['stat_admin', 'nutrition_admin'], } },
				],
			},
			{
				name: 'anamnese',
				path: 'anamnese',
				component: anamneseDashboard,
				children: [{ name: 'anamneseDashboardSearch', path: 'search', component: anamneseDashboardSearch, meta: { displayedTitle: 'Anamnesebögen' } }, { name: 'anamneseDashboardSettings', path: 'settings', component: anamneseDashboardSettings, meta: { displayedTitle: 'Anamnesebogen Einstellungen' } }],
			},
			{
				name: 'membercard',
				path: 'membercard',
				component: membercardDashboard,
				children: [
					{
						name: 'membercardDashboardAdmin', path: 'admin', component: membercardDashboardAdmin, meta: {
							displayedTitle: 'Membercards', needsLoader: true, rights: ['m_downloadList', 'm_signupUser', 'm_viewSubs', 'm_createSubs', 'm_createMembercard'],
						}
					}
				],
			},

			{
				name: 'timestop',
				path: 'timestop',
				meta: {},
				component: () => import('../views/dashboard/timestop/timestopView.vue'),
				childen: []
			},

			{
				name: 'timestopSettings',
				path: 'timestop/settings',
				meta: {},
				component: () => import('../views/dashboard/timestop/timestopSettings.vue'),
				childen: []
			},

			{
				name: 'leads',
				path: 'leads',
				component: leadsDashboard,
				children: [
					{ name: 'leadsDashboardLeadsDeprecated', path: 'referral-deprecated', component: leadsDashboardLeadsDeprecated, meta: { displayedTitle: 'Weiterempfehlungen (ALT)', needsLoader: true, rights: ['af_downloadList', 'af_view', 'af_edit', 'af_settingsEdit'], } },
					{ name: 'leadsDashboardLeadsList', path: 'leads-list', component: leadsDashboardLeadsList, meta: { displayedTitle: 'Anfragen Liste', needsLoader: true, rights: ['af_downloadList', 'af_view', 'af_edit', 'af_settingsEdit'], } },
					{ name: 'leadsDashboardReferralList', path: 'referral-list', component: leadsDashboardReferralList, meta: { displayedTitle: 'Weiterempfehlungen Liste', needsLoader: true, rights: ['af_downloadList', 'af_view', 'af_edit', 'af_settingsEdit'], } },
					{
						path: 'cost',
						name: 'leadsCostManager',
						meta: { displayedTitle: 'Lead Kosten', rights: ['af_cost'], },
						component: leadsCostManager,
					},
					{
						path: 'all',
						name: 'leadsDashboardAllLeads',
						meta: { displayedTitle: 'Alle Anfragen', rights: ['af_downloadList', 'af_view', 'af_edit', 'af_settingsEdit'], },
						component: () => import('../views/dashboard/leads/leadsDashboardAllLeads.vue'),
					},
					{ name: 'leadsDashboardReferralSettings', path: 'referral-settings', component: leadsDashboardReferralSettings, meta: { displayedTitle: 'Anfragen Einstellungen', needsLoader: true, rights: ['af_settingsEdit'], } },
					{ name: 'leadsDashboardSettings', path: 'leads-settings', component: leadsDashboardSettings, meta: { displayedTitle: 'Weiterempfehlungen Einstellungen', needsLoader: true, rights: ['af_leadSettings'], } },
				],
			},
			{
				name: 'groupFitness',
				path: 'gf',
				component: groupFitnessDashboard,
				children: [
					{ name: 'groupFitnessDashboardRatings', path: 'ratings', component: groupFitnessDashboardRatings, meta: { displayedTitle: 'Group Fitness Bewertungen', needsLoader: true, rights: ['gf_r_viewAll', 'gf_r_threestars'], } },
					{ name: 'groupFitnessDashboardCourses', path: 'courses', component: groupFitnessDashboardCourses, meta: { displayedTitle: 'Group Fitness Kurse', needsLoader: true, rights: ['gf_c_create', 'gf_c_edit', 'gf_c_delete', 'gf_c_plan'], } },
					{ name: 'groupFitnessDashboardLabels', path: 'labels', component: groupFitnessDashboardLabels, meta: { displayedTitle: 'Group Fitness Labels', needsLoader: true, rights: ['l_edit'], } },
					{ name: 'groupFitnessDashboardRooms', path: 'rooms', component: groupFitnessDashboardRooms, meta: { displayedTitle: 'Group Fitness Räume', needsLoader: true, rights: ['r_edit'], } },
					{ name: 'groupFitnessDashboardVideos', path: 'videos', component: groupFitnessDashboardVideos, meta: { displayedTitle: 'Group Fitness Videos', needsLoader: true, rights: ['a_add', 'a_delete', 'a_edit'], } },
					{ name: 'groupFitnessDashboardSettings', path: 'settings', component: groupFitnessDashboardSettings, meta: { displayedTitle: 'Group Fitness Einstellungen', needsLoader: true, rights: ['gf_c_signupRange'], } },
					{ name: 'groupFitnessDashboardDownloads', path: 'downloads', component: groupFitnessDashboardDownloads, meta: { displayedTitle: 'Group Fitness Downloads', needsLoader: true, rights: ['gf_c_plan'], } },
					{
						name: 'groupFitnessDashboardEventHome',
						path: 'event',
						rights: ['gf_c_create', 'gf_c_edit', 'gf_c_delete', 'gf_c_plan'],
						component: groupFitnessDashboardEventHome,
						children: [
							{ name: 'groupFitnessDashboardEventForm', path: 'create', component: groupFitnessDashboardEventForm, meta: { displayedTitle: 'Group Fitness Event erstellen', rights: ['gf_c_create', 'gf_c_edit', 'gf_c_delete', 'gf_c_plan'], } },
							{ name: 'groupFitnessDashboardEventForm', path: 'edit/:id', component: groupFitnessDashboardEventForm, meta: { displayedTitle: 'Group Fitness Event bearbeiten', rights: ['gf_c_create', 'gf_c_edit', 'gf_c_delete', 'gf_c_plan'], } },
						],
					},
				],
			},
			{
				name: 'user',
				path: 'user',
				component: userDashboard,
				children: [
					{ name: 'userDashboardSearch', path: 'search', component: userDashboardSearch, meta: { displayedTitle: 'Kundensuche', rights: ['u_search'] } },
					{ name: 'userDashboardCustomerSelected', path: 'search/:id', component: userDashboardCustomer, meta: { displayedTitle: 'Kundensuche', rights: ['u_search'] } },
					{ name: 'userDashboardRightList', path: 'rightlist', component: userDashboardRightList, meta: { displayedTitle: 'Rechte Liste', rights: ['u_rightList'] } }],
			},


		],
	},
	{
		path: '/notifications',
		name: 'notificationSettings',
		component: notificationSettings,
	},
	{
		path: '/intern/report/dashboard',
		name: 'viewProblem',
		component: viewProblem,
	},
	{
		path: '/intern/referral',
		name: 'internReferal',
		component: internReferal,
	},
	{
		path: '/fb/referral',
		name: 'LPReferral',
		component: LPReferral,
	},
	{
		path: '/referral',
		name: 'internReferralView',
		component: internReferralView,
	},
	{
		path: '/tools/marketing/email',
		name: 'mailCampaign',
		component: mailCampaign,
	},

	{
		path: '/roody/referral',
		name: 'LPReferral',
		component: LPReferral,
	},
	{
		path: '/google/referral',
		name: 'LPReferral',
		component: LPReferral,
	},
	{
		path: '/fb/referral/:status',
		name: 'LPReferralUrlFB',
		component: LPReferral,
	},
	{
		path: '/l/:link',
		name: 'PublicLeads',
		component: PublicLeads,
	},
	{
		path: '/r/:email',
		name: 'ReferralView',
		component: ReferralView,
	},
	{
		path: '/roody/referral/:status',
		name: 'LPReferralUrlRoody',
		component: LPReferral,
	},
	{
		path: '/google/referral/:status',
		name: 'LPReferralUrlGoogle',
		component: LPReferral,
	},
	{
		path: '/intern/report',
		name: 'reportProblem',
		component: reportProblem,
	},
	{
		path: '/alibey',
		name: 'AliBey',
		component: aliBey,
	},
	{
		path: '/alibey/booking',
		name: 'AliBeyBooking',
		component: aliBeyBooking,
	},
	{
		path: '/alibey/edit',
		name: 'AlibeyEditor',
		component: aliBeyEditor,
	},
	{
		path: '/centerdisplay/view/:id',
		name: 'fitnessCenterDisplay',
		component: fitnessCenterDisplay,
	},
	{
		path: '/centerdisplay',
		name: 'fitnessCenterDisplay',
		component: fitnessCenterDisplay,
	},

	{
		path: '/personaltraining',
		name: 'personalTraining',
		component: personalTraining,
	},
	{
		path: '/timestop',
		name: 'Timestop',
		component: Timestop,
	},
	{
		path: '/covidcerts/archive',
		name: 'Covid_archive',
		component: Covid_archive,
	},
	{
		path: '/covidcerts',
		name: 'Covid_check',
		component: Covid_check,
	},
	{
		path: '/statistik/kurse',
		name: 'Kursstatistik',
		component: Kursstatistik,
	},
	{
		path: '*',
		name: 'catchAll',
		component: HomeNew,
	},

	{
		path: '/helsana',
		name: 'Helsana',
		component: Helsana,
	},

	{
		path: '/sensordaten',
		name: 'Sensordaten',
		component: Sensordaten,
	},

	{
		path: '/pub/tendays/:userid',
		name: 'Tendays_Feedback',
		component: Tendays_Feedback,
	},
	{
		path: '/bewerten/trainingsplan/:plan_id',
		name: 'RateWorkouts',
		component: RateWorkouts,
	},
	{
		path: '/bewerten/anamnese/:anamnese_id',
		name: 'RateAnamnese',
		component: RateAnamnese,
	},
	{
		path: '/playground',
		name: 'Playground',
		component: Playground,
	},
	{
		path: '/timetrackeradmin',
		name: 'timetrackerAdmin',
		component: timetrackerAdmin,
	},
	{
		path: '/watch/:roomName',
		name: 'Livestream',
		component: Livestream,
	},
	{
		path: '/kurse/livestream/blacklist',
		name: 'Livestream_Blacklist',
		component: Livestream_Blacklist,
	},
	{
		path: '/foto',
		name: 'GSPhoto',
		component: GSPhoto,
	},
	{
		path: '/ebt',
		name: 'erstes_bestes_training',
		component: erstes_bestes_training,
	},
	{
		path: '/membercard',
		name: 'Membercard',
		component: Membercard,
	},
	{
		path: '/aktuelles',
		name: 'Aktuelles',
		component: Aktuelles,
	},
	{
		path: '/terminept',
		name: 'Terminplaner_pt',
		component: Terminplaner_pt,
	},
	{
		path: '/terminept/:studio',
		name: 'Terminplaner_auswahl_pt',
		component: Terminplaner_auswahl_pt,
	},
	{
		path: '/kampagne/:id',
		name: 'Kampagne_customer',
		component: Kampagne_customer,
	},
	{
		path: '/termine',
		name: 'Terminplaner',
		component: Terminplaner,
	},
	{
		path: '/termine/:studio',
		name: 'Terminplaner_auswahl',
		component: Terminplaner_auswahl,
	},
	{
		path: '/weiterempfehlung',
		name: 'Weiterempfehlung',
		component: Weiterempfehlung,
	},
	{
		path: '/home',
		name: 'Home2',
		component: Home2,
	},
	{
		path: '/anamneseboegen',
		name: 'Anamneseboegen',
		component: Anamneseboegen,
	},
	{
		path: '/videos',
		meta: { navbarTitle: 'Videoarchiv' },
		name: 'Videoarchiv',
		component: Videoarchiv,
	},
	/*
	{
	path: "/adventsgewinnspiel",
	name: "Adventsgewinnspiel",
	component: Adventsgewinnspiel,
	},
	*/
	{
		path: '/zeitgutschrift',
		name: 'Zeitgutschrift',
		component: Zeitgutschrift,
	},
	/*
	{
	path: "/adventsgewinnspiel/:standort",
	name: "Adventsgewinnspiel",
	component: Adventsgewinnspiel,
	},
	{
	path: "/adventsverlosung/:standort",
	name: "Adventsgewinnspiel",
	component: Adventsgewinnspiel,
	},
	{
	path: "/adventsverlosung",
	name: "Adventsgewinnspiel",
	component: Adventsgewinnspiel,
	},
	*/
	{
		path: '/friends',
		meta: { navbarTitle: 'Freunde' },
		name: 'Friends',
		component: Friends,
	},
	{
		path: '/kurseinstellungen',
		name: 'Kurseinstellungen',
		component: Kurseinstellungen,
	},
	/*
	{
	path: "/bewertungen/studiovergleich",
	name: "Studiovergleich",
	component: Studiovergleich,
	},
	*/
	{
		path: '/bewertungen/kt',
		name: 'Kreuztabelle',
		component: Kreuztabelle,
	},
	{
		path: '/intern',
		name: 'Intern',
		component: Intern,
	},
	{
		path: '/bewertungenneu/kt',
		name: 'Kreuztabellen',
		component: Kreuztabellen,
	},
	{
		path: '/bewertungen',
		name: 'Bewertungen',
		component: Bewertungen,
	},
	{
		path: '/kursbewertungen',
		name: 'Kursbewertungen',
		component: Kursbewertungen,
	},
	{
		path: '/impressum',
		name: 'Impressum',
		component: Impressum,
	},
	{
		path: '/datenschutz',
		name: 'Datenschutz',
		component: Datenschutz,
	},
	{
		path: '/survey',
		name: 'Survey',
		component: Survey,
	},
	{
		path: '/Printer/:id',
		name: 'Printer',
		component: Printer,
	},
	{
		path: '/instruktoren',
		name: 'instruktoren',
		component: Instruktoren,
	},
	{
		path: '/rollenverwaltung',
		name: 'rollenverwaltung',
		component: Rollenverwaltung,
	},
	{ path: '/redirect/:routeName', name: 'redirection', component: redirection },
	{ path: '/gfrecommendations/:userId', name: 'gfrecommendations', component: gfRecommendations },
	{ path: '/kursliste/:studiocode', name: 'courseListWeekly', component: courseListWeekly },
	{ path: '/kursliste/:studiocode/clean', name: 'courseListWeeklyClean', component: courseListWeekly },
	{
		path: '/kurslisten',
		name: 'courseEdit',
		component: courseEdit,
		children: [
			{ path: 'add', name: 'courseCreateTimeline', component: courseCreateTimeline },
			{ path: 'edit/:id', name: 'courseEditTimeline', component: courseEditTimeline },
		],
	},
	{
		path: '/kurse',
		name: 'courseList',
		meta: { navbarTitle: 'Group Fitness' },
		component: courseList,
		children: [
			{ path: ':id', name: 'courseListView', component: courseListView },
			{ path: ':id/edit', name: 'courseListEdit', component: courseListEdit },
		],
	},
	{
		path: '/kursliste/:id',
		name: 'Kurse_extern',
		component: Kurse_extern,
	},
	{
		path: '/forgot',
		name: 'Forgot',
		component: () => import('../views/launchScreen/launchScreen.vue'),
	},
	{
		path: '/wheel',
		name: 'spinningWheel',
		meta: { hideBottomNavigation: true, hideAppBar: true, fullWidth: true },
		component: () => import('../views/marketing/spinningWheel/spinningWheel.vue'),
	},

	{
		path: '/push',
		name: 'Push',
		component: Push,

	},
	{
		path: '/schnupperwoche',
		name: 'Schnupperwoche',
		component: Schnupperwoche,
	},
	{
		path: '/schnupperwoche/:status',
		name: 'Schnupperwoche_url',
		component: Schnupperwoche,
	},
	{
		path: '/schnupperwochen',
		name: 'SchnupperwocheGoogle',
		component: Schnupperwoche,
	},
	{
		path: '/schnupperwochen/:status',
		name: 'Schnupperwoche_urlGoogle',
		component: Schnupperwoche,
	},
	{
		path: '/weiterempfehlungen',
		name: 'Weiterempfehlungen',
		component: Weiterempfehlungen,
	},
	{
		path: '/zoom',
		name: 'Zoom',
		component: Zoom,
	},
	{
		path: '/workouts',
		name: 'Workouts',
		component: DWorkouts,
		meta: { navbarTitle: 'Deine Workouts' },
		children: [
			{ path: 'create', name: 'WorkoutCreate', component: WorkoutCreate },
			{ path: 'edit/:id', name: 'WorkoutEdit', component: WorkoutCreate },
		],
	},
	{
		path: '/workouts/completed',
		name: 'WorkoutCompleted',
		component: WorkingoutCompleted,
	},
	{
		path: '/workouts/completed/pre',
		name: 'WorkoutCompletedOld',
		component: WorkingoutCompletedOld,
	},
	{
		path: '/kurse/edit/zeitplan/:id',
		name: 'Zeitplan',
		component: Zeitplan,
	},
	{
		path: '/workouts/run/:id',
		name: 'WorkoutRun',
		component: WorkoutRun,
	},
	{
		path: '/plan/run/:id',
		name: 'planRun',
		component: PlanRun,
	},
	{
		path: '/workouts/statistik',
		name: 'WorkoutStatistik',
		component: WorkoutStatistik,
	},
	{
		path: '/kurse/edit/zeitplaene/:id',
		name: 'Zeitplaene',
		component: Zeitplaene,
	},
	{
		path: '/kurse/create/zeitplan/:id',
		name: 'ZeitplanCreator',
		component: ZeitplanCreator,
	},
	{
		path: '/edituebungen',
		name: 'UebungenEditor',
		component: UebungenEditor,
	},

	{
		path: '/devices',
		name: 'DeviceList',
		component: DeviceList,
		children: [
			{
				path: 'create',
				name: 'DeviceCreate',
				component: DeviceForm,
				children: [
					{
						path: 'exercise/create',
						name: 'DeviceExerciseCreate',
						component: DeviceExerciseForm,
					},
				],
			},
			{
				path: ':id',
				name: 'DeviceEdit',
				component: DeviceForm,
				children: [
					{
						path: 'exercise/create',
						name: 'DeviceExerciseCreate',
						component: DeviceExerciseForm,
					},
					{
						path: 'exercise/:eid',
						name: 'DeviceExerciseEdit',
						component: DeviceExerciseForm,
					},
				],
			},
		],
	},
	{
		path: '/kurse/anmeldung/:id',
		name: 'Kursanmeldung',
		component: Kursanmeldung,
	},

	/** 	{
		path: '/statistik',
		name: 'Statistik',
		component: Statistik,
	},*/
	{
		path: '/einstellungen',
		name: 'Einstellungen',
		component: Einstellungen,
	},
	{
		path: '/einstellungen/kurse',
		name: 'courseSettings',
		component: courseSettings,
	},
	{
		path: '/einstellungen/leads',
		name: 'Leads',
		component: Leads,
	},
	{
		path: '/einstellungen/businesshours',
		name: 'businessHours',
		component: businessHours,
	},
	{
		path: '/tools/sysadmin/rights',
		name: 'rights',
		component: rights,
	},

	{
		path: '/management',
		name: 'Management',
		component: Management,
	},
	{
		path: '/allianz',
		meta: { navbarTitle: 'Allianz' },
		name: 'allianzAssociation',
		component: () => import('../views/einmalig/allianzAssociation.vue'),
	},
	{
		path: '/about',
		name: 'About',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
	},
	{
		path: '/login',
		name: 'login',
		component: () => import('../views/launchScreen/launchScreen.vue'),
	},
	{
		path: '/register',
		name: 'Registrieren',
		component: () => import('../views/launchScreen/launchScreen.vue'),
	},
	{
		path: '/profile',
		name: 'Profil',
		component: () => import('../views/Profile.vue'),
	},
	{
		path: '/user',
		name: 'userProfile',
		component: () => import('../views/user/UserProfile.vue'),
	},
	{
		path: '/user/edit',
		name: 'userProfile',
		component: () => import('../views/user/views/UserEditProfile.vue'),
	},

	{
		path: '/user/payments',
		name: 'userPayments',
		meta: {
			hideBottomNavigation: true,
		},
		component: () => import('../views/user/views/UserPayments.vue'),
	},

	{
		path: '/user/healthcare',
		meta: { navbarTitle: 'Krankenkasse' },
		name: 'userHealthcare',
		component: () => import('../views/user/views/UserHealthcare.vue'),
	},
	{
		path: '/user/purchases',
		name: 'userPurchases',
		component: () => import('../views/shop/PurchasesProfile.vue'),
	},
	{
		path: '/user/payments',
		name: 'userPayments',
		component: () => import('../views/user/views/UserPayments.vue'),
	},
	{
		path: '/event/create',
		name: 'EventCreate',
		component: EventForm,
	},
	{
		path: '/event/edit/:id',
		name: 'EventEdit',
		component: EventForm,
	},
	{
		path: '/event/:id',
		name: 'EventView',
		component: EventView,
	},
	{
		path: '/event',
		name: 'EventHome',
		component: EventHome,
	},
	{
		path: '/weihnachten',
		name: 'ChristmasHome',
		component: ChristmasHome,
	},
	{
		path: '/habits',
		name: 'HabitHome',
		component: habitHome,
	},
	{
		path: '/einstein/contest',
		name: 'EinsteinSaunaContest',
		component: einsteinSaunaContest,
	},
	{
		path: '/einstein/contest/admin/list',
		name: 'EinsteinSaunaContestAdminList',
		component: einsteinSaunaContestAdminList,
	},
	{
		path: '/nutrition',
		name: 'nutritionMainpage',
		component: nutritionRoute,
		children: [
			{
				path: '', name: 'nutritionMainpage', component: nutritionMainpage, meta: {
					hideBottomNavigation: true,
					navbarTitle: 'Ernährungsplan'
				}
			},
			{
				path: 'recipes', name: 'nutritionRecipes', props: true, component: nutritionRecipes, meta: {
					hideBottomNavigation: true,
					navbarTitle: 'Rezepte'
				}
			},
			{
				path: 'shopping-list', name: 'nutritionShoppingList', props: true, component: nutritionShoppinList, meta: {
					hideBottomNavigation: true,
					navbarTitle: 'Shopping List'
				}
			},
		]
	},
	{
		path: '/shop',
		name: 'shopMainpage',
		component: shopRoute,
		children: [
			{
				path: '', name: 'shopMainPage', component: shopMainpage, meta: {
					hideBottomNavigation: true
				}
			},
			{
				path: 'checkout', name: 'shopCheckout', component: shopCheckout, meta: {
					hideBottomNavigation: true
				}
			},
			{
				path: 'payment/failed', name: 'shopPaymentFailed', component: shopCheckPaymentFailed, meta: {
					hideBottomNavigation: true
				}
			},
			{
				path: 'check/payment', name: 'shopCheckPayment', component: shopCheckPayment, meta: {
					hideBottomNavigation: true
				}
			},
			{
				path: 'purchase/infos/:id', name: 'shopPurchaseInfos', component: shopPurchaseInfos, meta: {
					hideBottomNavigation: true
				}
			},
			{
				path: ':id', name: 'shopMainPageProduct', component: shopMainpage, meta: {
					hideBottomNavigation: true
				}
			},
		],
		meta: {
			hideBottomNavigation: true
		}
	},
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
})

//let isNavigating = false

import { store } from '../store/stores'
import userService from '../services/userService'

const waitForStorageToBeReady = async (to, from, next) => {
	console.log('Navigating from:', from.fullPath, 'to:', to.fullPath)
	await store.restored

	if (to && to.meta && to.meta.rights) {
		if (!userService.hasRightArray(to.meta.rights)) {
			console.log('No rights, redirecting to Home from:', from.fullPath, 'to:', to.fullPath)
			return next({ name: 'Home' })
		}
	}

	next()
}
router.beforeEach(waitForStorageToBeReady)

export default router
