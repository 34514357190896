var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"white--text",staticStyle:{"background-color":"#343541"}},[(!_vm.$vuetify.breakpoint.mobile)?_c('v-data-table',{staticClass:"elevation-5 trainer-statistics__table",attrs:{"dark":"","search":_vm.search,"loading":!_vm.finishedLoading,"headers":_vm.groupfitnessCoursesTable,"no-data-text":"Keine Trainer gefunden...","loading-text":"Trainerstatistiken werden geladen...","items":_vm.filteredDashboardTrainerInfos,"items-per-page":8},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-row',[_c('v-col',{staticStyle:{"flex-grow":"2"}},[_c('v-text-field',{staticClass:"mx-4",attrs:{"label":"Suche"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{staticStyle:{"align-self":"center"}},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){_vm.percentagesTo100 = !_vm.percentagesTo100}}},[_vm._v(" "+_vm._s(_vm.percentagesTo100 ? 'Originalwerte anzeigen' : 'Stellenprozente aufrechnen')+" ")])],1),_c('v-col',{staticStyle:{"align-self":"center"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var props = ref.props;
var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"white"}},'v-btn',props,false),on),[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-information")])],1)]}}],null,false,545240467)},[_c('div',{staticClass:"d-flex flex-column"},[_c('span',[_vm._v("Ausgeblendete Mitarbeiter (kein Vertrag, TP, und Anamnese):")]),_vm._l((_vm.notShownTrainers),function(trainer){return _c('p',{key:'notshown' + trainer._id},[_vm._v(" "+_vm._s(trainer.fullName)+" ")])}),(!_vm.notShownTrainers.length)?_c('span',[_vm._v("Es wurde kein Trainer ausgeblendet")]):_vm._e()],2)])],1)],1)]},proxy:true},{key:"item.percentage",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var props = ref.props;
var on = ref.on;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"justify-content-center font-weight-bold",staticStyle:{"min-width":"50%"},attrs:{"color":"grey","dark":""}},'v-chip',props,false),on),[_vm._v(" "+_vm._s(item.percentage + '%')+" ")])]}}],null,true)},[_c('div',{staticClass:"d-flex flex-column"},[_c('span',[_vm._v("Stellenprozent Berechnung:")]),_vm._l((item.allPercentages),function(percentageItem,index){return _c('span',{key:percentageItem._id + item.fullName + index},[_vm._v(" "+_vm._s(_vm.storeStudios.find(function (item) { return item._id === percentageItem._id; }) ? _vm.storeStudios.find(function (item) { return item._id === percentageItem._id; }).name + ' ' + percentageItem.percentage + '%' : percentageItem.percentage + '%')+" ")])})],2)])]}},{key:"item.contracts",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var props = ref.props;
var on = ref.on;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"justify-content-center font-weight-bold",staticStyle:{"min-width":"50%"},attrs:{"color":_vm.getContractColor(item.contracts),"dark":""}},'v-chip',props,false),on),[_vm._v(" "+_vm._s(item.contracts)+" ")])]}}],null,true)},[_vm._v(" "+_vm._s('Durchschnittliche Verträge: ' + Math.round(_vm.contractsOnAverage * 100) / 100)+" ")])]}},{key:"item.avgPackages",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var props = ref.props;
var on = ref.on;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"justify-content-center font-weight-bold",staticStyle:{"min-width":"50%"},attrs:{"color":_vm.getPackageColor(item.avgPackages),"dark":""}},'v-chip',props,false),on),[_vm._v(" "+_vm._s(item.avgPackages)+" ")])]}}],null,true)},[_c('p',[_vm._v(" "+_vm._s('Gesammt Verkauft:' + Math.round(item.avgPackages * item.contracts))+" ")]),_c('p',[_vm._v(" "+_vm._s('Durchschnittliche Pakete: ' + Math.round(_vm.packagesOnAverage * 100) / 100)+" ")])])]}},{key:"item.medicalHistory",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var props = ref.props;
var on = ref.on;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"justify-content-center font-weight-bold",staticStyle:{"min-width":"50%"},attrs:{"color":_vm.getMedicalHistoryColor(item.medicalHistory),"dark":""}},'v-chip',props,false),on),[_vm._v(" "+_vm._s(item.medicalHistory)+" ")])]}}],null,true)},[_vm._v(" "+_vm._s('Durchschnittliche Anamnesebögen: ' + Math.round(_vm.medicalHistoriesOnAverage * 100) / 100)+" ")])]}},{key:"item.workouts",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var props = ref.props;
var on = ref.on;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"justify-content-center font-weight-bold",staticStyle:{"min-width":"50%"},attrs:{"color":_vm.getWorkoutColor(item.workouts),"dark":""}},'v-chip',props,false),on),[_vm._v(" "+_vm._s(item.workouts)+" ")])]}}],null,true)},[_vm._v(" "+_vm._s('Durchschnittliche Trainingspläne: ' + Math.round(_vm.workoutsOnAverage * 100) / 100)+" ")])]}},{key:"item.packageCount",fn:function(ref){
var item = ref.item;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"justify-content-center font-weight-bold",staticStyle:{"min-width":"50%"},attrs:{"color":_vm.getPackageColor(item.avgPackages),"dark":""}},'v-chip',_vm.props,false),_vm.on),[_vm._v(" "+_vm._s(item.packageCount)+" ")])]}},{key:"item.quote",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var props = ref.props;
var on = ref.on;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"justify-content-center font-weight-bold",staticStyle:{"min-width":"50%"},attrs:{"color":_vm.getQuoteColor(item.quote),"dark":""}},'v-chip',props,false),on),[_vm._v(" "+_vm._s(item.quote !== null ? item.quote + '%' : '-')+" ")])]}}],null,true)},[_c('p',[_vm._v(" "+_vm._s('Von: ' + item.medicalHistory + ' Anamnesebögen haben ' + Math.round((item.quote / 100) * item.medicalHistory) + ' einen Vertrag abgeschlossen')+" ")]),_c('p',[_vm._v(_vm._s('Durchschnittliche Versch. Kunden: ' + Math.round(_vm.quoteOnAverage * 100) / 100)+" %")])])]}},{key:"item.workoutsPerCustomer",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var props = ref.props;
var on = ref.on;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"justify-content-center font-weight-bold",staticStyle:{"min-width":"50%"},attrs:{"color":_vm.getWorkoutPerCustomerColor(item.workoutsPerCustomer),"dark":""}},'v-chip',props,false),on),[_vm._v(" "+_vm._s(item.workoutsPerCustomer)+" ")])]}}],null,true)},[_c('p',[_vm._v(" "+_vm._s('Durchschnitt: ' + Math.round(_vm.workoutsPerCustomerOnAverage * 100) / 100)+" ")])])]}},{key:"item.interactions.totalInteractions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var props = ref.props;
var on = ref.on;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"justify-content-center font-weight-bold",staticStyle:{"min-width":"50%"},attrs:{"color":_vm.getCustomerInteractionsColor(item.interactions.totalInteractions),"dark":""}},'v-chip',props,false),on),[_vm._v(" "+_vm._s(item.interactions.totalInteractions)+" ")])]}}],null,true)},[_c('p',[_vm._v(" "+_vm._s('Durchschnitt: ' + Math.round(_vm.interactionsPerEmployeeOnAverage * 100) / 100)+" ")])])]}}],null,false,2921997959)}):_vm._e(),_c('v-row',{staticClass:"mt-5"},[_c('v-col',{staticClass:"d-flex justify-center",attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"outlined":"","color":"primary"},on:{"click":function($event){_vm.showInteractionDialog = true}}},[_vm._v(" Übersicht der Kundeninteraktionen ")]),_c('v-dialog',{attrs:{"max-width":"800px","value":_vm.showInteractionDialog}},[(_vm.showInteractionDialog)?_c('v-card',[_c('v-card-title',{staticClass:"primary white--text"},[_vm._v(" Kundeninteraktionen von "+_vm._s(_vm.datePickerRange[0] ? _vm.$moment(_vm.datePickerRange[0]).format('DD.MM.YYYY') : '-')+" bis "+_vm._s(_vm.datePickerRange[1] ? _vm.$moment(_vm.datePickerRange[1]).format('DD.MM.YYYY') : '-')+" ")]),_c('v-simple-table',[_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v("Datum")]),_c('th',{staticClass:"text-left"},[_vm._v("Trainer")]),_c('th',{staticClass:"text-left"},[_vm._v("Kunde")]),_c('th',{staticClass:"text-left"},[_vm._v("Wo")]),_c('th',{staticClass:"text-left"},[_vm._v("Was")]),_c('th',{staticClass:"text-left"},[_vm._v("Notiz")])])]),_c('tbody',_vm._l((_vm.allInteractions.sort(
                                        function (a, b) { return new Date(b.date) - new Date(a.date); }
                                    )),function(customerInteraction){return _c('tr',{key:customerInteraction.date},[_c('td',{attrs:{"width":"120"}},[_vm._v(" "+_vm._s(_vm.$moment(customerInteraction.date).format('DD.MM.YYYY'))+" ")]),_c('td',{attrs:{"width":"80"}},[(customerInteraction.fullName)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                    var on = ref.on;
                                    var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(" "+_vm._s(_vm.truncateName(customerInteraction.fullName))+" ")])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(customerInteraction.fullName)+" ")])]):_vm._e()],1),_c('td',{attrs:{"width":"80"}},[(customerInteraction.user && customerInteraction.user.vorname)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                    var on = ref.on;
                                    var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(" "+_vm._s(customerInteraction.user.vorname.slice(0, 1))+_vm._s(customerInteraction.user.nachname.slice(0, 1))+" ")])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(customerInteraction.user.vorname)+" "+_vm._s(customerInteraction.user.nachname)+" "+_vm._s(customerInteraction.user.email)+" ")])]):_vm._e()],1),_c('td',{attrs:{"width":"150"}},[_vm._v(" "+_vm._s(customerInteraction.location)+" ")]),_c('td',{attrs:{"width":"200"}},[_vm._v(" "+_vm._s(customerInteraction.reason)+" ")]),_c('td',{attrs:{"width":"200"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                    var on = ref.on;
                                    var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(" "+_vm._s(_vm.truncateText(customerInteraction.notes, 50))+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(customerInteraction.notes))])])],1)])}),0)]),_c('v-card-actions',{staticClass:"d-flex justify-center"},[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.showInteractionDialog = false}}},[_vm._v("Schliessen")])],1)],1):_vm._e()],1)],1),_c('v-col',{attrs:{"cols":"7"}},[_c('v-card-title',{staticClass:"white--text"},[_vm._v("Trainer")])],1),_c('v-col',{attrs:{"cols":"5"}},[_c('v-select',{attrs:{"label":"sortieren","dark":"","filled":"","items":_vm.sortItems},model:{value:(_vm.activeSorting),callback:function ($$v) {_vm.activeSorting=$$v},expression:"activeSorting"}})],1)],1),_c('v-row',_vm._l((_vm.filteredDashboardTrainerInfos),function(trainer,index){return _c('v-col',{key:'trainer' + index,staticStyle:{"margin-top":"60px"},attrs:{"cols":_vm.$vuetify.breakpoint.mobile ? 12 : 4}},[_c('v-card',{staticClass:"d-flex flex-column justify-content-center text-center rounded-xl",staticStyle:{"height":"100%"},attrs:{"dark":"","hover":""},on:{"click":function($event){_vm.selectedTrainer = trainer}}},[_c('v-avatar',{staticClass:"employee-manager__card-avatar ma-auto",staticStyle:{"position":"absolute","top":"-62px","left":"0","right":"0"},attrs:{"color":"grey","size":"125"}},[_c('img',{attrs:{"src":trainer.picture
                                    ? '/img/profilbilder' + trainer.picture
                                    : '/img/einmalig/imageUploadPlaceholderPFP.jpg'}})]),_c('v-card-title',{staticClass:"justify-content-center text-break",staticStyle:{"margin-top":"55px"}},[_vm._v(" "+_vm._s(trainer.fullName)+" ")]),_c('v-card-subtitle',_vm._l((trainer.studio.split(',')),function(std){return _c('v-chip',{key:trainer._id + std,staticClass:"mx-1",class:{
                                primary:
                                    _vm.storeStudios.find(function (x) { return x.name === std; }) &&
                                    _vm.storeStudios.find(function (x) { return x.name === std; })._id === trainer.mainStudio,
                            }},[_vm._v(" "+_vm._s(std)+" ")])}),1),_c('v-card-text',{staticClass:"py-1"},[_vm._v(" "+_vm._s(trainer.interactions ? trainer.interactions.totalInteractions : 'Keine')+" Interaktionen ")]),_c('v-card-text',{staticClass:"py-1"},[_vm._v(" "+_vm._s(trainer.workouts ? trainer.workouts : 'Keine')+" Trainingspläne ")]),_c('v-card-text',{staticClass:"py-1"},[_vm._v(" "+_vm._s(trainer.medicalHistory ? trainer.medicalHistory : 'Keine')+" Anamnesebögen ")]),_c('v-card-text',{staticClass:"py-1"},[_vm._v(" "+_vm._s(trainer.contracts ? trainer.contracts : 'Keine')+" Verträge abgeschlossen ")]),(trainer.contracts)?_c('v-card-text',{staticClass:"py-1"},[_vm._v(" "+_vm._s(trainer.avgPackages ? trainer.avgPackages : 'Keine')+"Ø Pakete verkauft ")]):_vm._e(),_c('v-card-text',{staticClass:"py-1"},[_vm._v(" Trainer "),_c('v-rating',{attrs:{"value":trainer.tp_question_1,"color":"yellow darken-3","background-color":"grey darken-1","empty-icon":"$ratingFull","half-increments":"","readonly":""}})],1),_c('v-card-text',{staticClass:"py-1"},[_vm._v(" Trainingsplan "),_c('v-rating',{attrs:{"value":trainer.tp_question_2,"color":"yellow darken-3","background-color":"grey darken-1","empty-icon":"$ratingFull","half-increments":"","readonly":""}})],1)],1)],1)}),1)],1),(_vm.selectedTrainer)?_c('home-dashboard-trainer-statistics-dialog',{attrs:{"trainer":_vm.selectedTrainer,"studioTrainers":_vm.selectedTrainer.mainStudio
                ? _vm.dashboardTrainerInfos.filter(function (trainer) { return trainer.studio.find(function (x) { return x === _vm.selectedTrainer.mainStudio; }); }
                  )
                : _vm.dashboardTrainerInfos.filter(function (trainer) { return trainer.studio.find(function (x) { return x === _vm.selectedTrainer.studio[0]; }); }
                  ),"datePickerRange":_vm.datePickerRange},on:{"close":_vm.closeDialog}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }